import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent  {
  public myInterval: number = 3000;
  public activeSlideIndex: number = 0;
  public noWrapSlides:boolean = false;

  activeSlideChange(){
    console.log(this.activeSlideIndex);
  }

  public slides:Array<Object> = [
    {"image":"https://mdbootstrap.com/img/Photos/Slides/img%20(18).jpg"},
    {"image":"https://mdbootstrap.com/img/Photos/Slides/img%20(19).jpg"},
    {"image":"https://mdbootstrap.com/img/Photos/Slides/img%20(20).jpg"},
  ];
}
